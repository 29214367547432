import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import Grid2Auto from '../components/Layout/Grid2/Grid2Auto'
import GridItem from '../components/Layout/GridItem/GridItem'
import Heading2 from '../components/Type/Heading2'
import Paragraph1 from '../components/Type/Paragraph1'
import ImageRoundButton from '../components/Layout/Button/ImageRoundButton'

const articlesPage = ({ location, data }) =>
(
	<Layout>

		<SEO title="Summon Articles"
			description="The articles, guides and resources below we hope can help to make selling your 
				prestige used car, or any car for that matter, a joyful experience."
			location={location}
		/>

		<PageHeader
			title="Articles"
			text="Whether you are planning to sell your car privately, sell to a dealer or get help 
						from Summon, we want to give you the best tools and advice to help make the process as 
						pain-free as possible and ensure you get the most value for your prestige car. 
						The articles, guides and resources below we hope can help to make selling your 
						prestige used car, or any car for that matter, a joyful experience."
		/>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/how-to-sell-a-car-on-consignment/">
						How to sell a car on consignment
						</HeadingLink>
					</Heading2>
					<Paragraph1>
					Thinking about selling your car on consignment? Read our tips first
					</Paragraph1>
				</GridItem>

				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/how-to-sell-a-car-on-consignment/">
						<BackgroundImage
							fluid={data.consignment.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>

			</Grid2Auto>
		</SectionPageMargin>

		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/onda/">
						<BackgroundImage
							fluid={data.ondaLogo.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/onda/">
							Summon launch exclusive partnership with ONDA
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						Summon are pleased to announce our exclusive partnership with ONDA, providing financial administration services for busy Australian individuals and families
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/chartered-accountants-law-institute-of-victoria/">
							Summon partners with Chartered Accountants and Law Institute of Victoria
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						Summon have partnered with Chartered Accountants ANZ and Law Institute of Victoria to offer exclusive concierge services for members
					</Paragraph1>
				</GridItem>

				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/chartered-accountants-law-institute-of-victoria/">
						<BackgroundImage
							fluid={data.caPartner.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>

			</Grid2Auto>
		</SectionPageMargin>




		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/buying-guides/guides/">
						<BackgroundImage
							fluid={data.buyingGuides.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/buying-guides/guides/">
							Buying a prestige car? Read our Buyer Guides for tips on getting the best price and the right car
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						Summon have launched Australia’s first full ecommerce service for prestige cars, offering enthusiasts a completely contactless way to buy and sell vehicles privately.
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/good-design-awards-winner/">
							Summon recognised in Australia’s International Good Design Awards for Design Excellence
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						Summon has received a prestigious Good Design Award Winner Accolade in the Service Design category in recognition for outstanding design and innovation.
					</Paragraph1>
				</GridItem>

				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/good-design-awards-winner/">
						<BackgroundImage
							fluid={data.goodDesignAwards.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>

			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/ecommerce-launch/">
						<BackgroundImage
							fluid={data.ecommerceLaunch.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/ecommerce-launch/">
							Summon launches Australia’s first eCommerce service for prestige car owners
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						Summon have launched Australia’s first full ecommerce service for prestige cars, offering enthusiasts a completely contactless way to buy and sell vehicles privately.
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/used-car-sales-in-lockdown/">
							Used car sales in lockdown, turning the corner?
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						With strict lockdowns in place in Australia’s two largest car markets, what has been the impact on how long it takes to sell a used car?
					</Paragraph1>
				</GridItem>

				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/used-car-sales-in-lockdown/">
						<BackgroundImage
							fluid={data.lockdownSales.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>

			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/selling-your-car-privately-during-lockdowns/">
						<BackgroundImage
							fluid={data.lockdown.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/selling-your-car-privately-during-lockdowns/">
							Selling your car privately during lockdowns
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						With rolling lockdowns seemingly a part of life in Australia for the next six months, how do you sell your car privately?
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>



		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/award-winning-service/">
							Award-winning service
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						In the past month Summon have been recognised in two industry award programs for the quality of our service to customers, and our differentiation in the market.
					</Paragraph1>
				</GridItem>

				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/award-winning-service/">
						<BackgroundImage
							fluid={data.awardWinning.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>

			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/celebrating-100-customers-with-summon/">
						<BackgroundImage
							fluid={data.summon100.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/celebrating-100-customers-with-summon/">
							Celebrating 100 customers with Summon
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						We're celebrating a major milestone for Summon this month - we just signed up our 100th customer! That's 100 prestige cars entrusted by the owner with us to sell on their behalf, and the average value of our customer's cars is $100,000. Now onto the next 100!
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>



		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/porsche-911-991-GTS/">
							Porsche 911 991 GTS prices - at their peak?
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						We recently (May 2021) did some research into appreciation / depreciation of Porsche 911 GTS’, along with a couple of other models. What we found surprised us, and made us question whether prices have peaked, or will continue to rise.
					</Paragraph1>
				</GridItem>

				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/porsche-911-991-GTS/">
						<BackgroundImage
							fluid={data.porsche911.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>

			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/fastest-selling-cars-in-australia/">
						<BackgroundImage
							fluid={data.fastestSelling.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/fastest-selling-cars-in-australia/">
							The fastest (selling) prestige cars in Australia
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						As most Australian’s will be aware, used car prices have been running hot for the past six months, having experienced a ‘COVID bounce’ like never seen before. We wanted to see how this was affecting the speed at which cars are selling, and which makes and models are selling the fastest.
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/photo-guide/">
							How to take incredible photos of your prestige car before sale
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						If you are planning on selling your prestige car yourself privately, do yourself and your car a favour and take high quality photos of your car. Download our free guide and get started taking great photos.
					</Paragraph1>
				</GridItem>

				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/photo-guide/">
						<BackgroundImage
							fluid={data.photoGuide.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>

			</Grid2Auto>
		</SectionPageMargin>

		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/free-ppsr-check/">
						<BackgroundImage
							fluid={data.freePPSR.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/free-ppsr-check/">
							Free PPSR check for prestige cars
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						If you are buying or selling a car privately, you will want to perform a PPSR check to
						confirm the car has not been stolen or written off, and whether there is finance owing.
						We are now offering a free PPSR check service for a range of prestige cars.
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="left">
					<Heading2>
						<HeadingLink to="/articles/how-to-get-best-trade-in-price/">How to get the best trade-in price for your prestige car</HeadingLink>
					</Heading2>
					<Paragraph1>
						If you are considering trading-in your prestige car, you are probably expecting to be
						disappointed with the price on offer. Here are our tips for how to get the best possible
						trade-in price for your car.
					</Paragraph1>
				</GridItem>
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/how-to-get-best-trade-in-price/">
						<BackgroundImage
							fluid={data.tradeIn.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>


		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/australian-car-sales-rollercoaster/">
						<BackgroundImage
							fluid={data.rollercoaster.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/australian-car-sales-rollercoaster/">
							The 2020 Australian car sales rollercoaster
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						With the release of the new car sales figures for July, we can see just what the
						impact of phased lockdowns across Australia has meant for the automotive sales
						industry. A dramatic drop in April was followed by an incredible high
						in June, which seems to be a bounce not likely to be repeated until
						the current lockdown's are relaxed.
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>

		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="left">
					<Heading2>
						<HeadingLink to="/articles/zoom-garage/">Dream Garage Zoom Backgrounds</HeadingLink>
					</Heading2>
					<Paragraph1>
						If you’re anything like us, our life has turned into a never-ending schedule of
						Zoom calls, and meantime our cars collect dust in the garage. This gave us the
						idea to combine the two - now you can show off your (dream) garage to your
						workmates on your next video call, and best of all your car always looks
						like it’s come straight from the valet!
					</Paragraph1>
				</GridItem>
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/zoom-garage/">
						<BackgroundImage
							fluid={data.dreamGarage.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>

		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/2020-guide-checklist/">
						<BackgroundImage
							fluid={data.guideChecklist.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/2020-guide-checklist/">
							Guide to selling a prestige used car
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						If you’re planning on selling a prestige car soon, you’re probably trying to decide
						between selling the car privately (what a hassle), or selling it to a dealer
						(which can mean you don’t get as good a price). We’re here to help take the
						hassle out of selling privately, and have put together a guide with ideas and
						suggestions, along with a free downloadable checklist.
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>

		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="1" align="left">
					<Heading2>
						<HeadingLink to="/articles/calculator/">
							Calculator: selling private vs dealer
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						For most people looking to sell a car, there are three options; sell the car privately,
						sell the car to a dealer, or sell with a dealer on consignment. Each of these will
						leave a different amount of money in your pocket, and this calculator helps you learn
						what that could be. So whether you plan to sell privately, or to a dealer - make an
						informed decision first.
					</Paragraph1>
				</GridItem>
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/calculator/">
						<BackgroundImage
							fluid={data.calculator.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>

		<SectionPageMargin>
			<Grid2Auto >
				<GridItem order="2" >
					<ImageRoundButton size="200px" url="/articles/car-valuations/">
						<BackgroundImage
							fluid={data.howMuch.childImageSharp.fluid}
							style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
							}}
						/>
					</ImageRoundButton>
				</GridItem>
				<GridItem order="1" align="right">
					<Heading2>
						<HeadingLink to="/articles/car-valuations/">
							How much is my car worth?
							Solving the impossible challenge!
						</HeadingLink>
					</Heading2>
					<Paragraph1>
						If you’re selling a prestige car, it’s important to know it’s true worth. There are a
						number of independent valuation Web sites in Australia, and we have put them to
						the test. The results prove just how difficult it can be to work out the
						correct value of your car.
					</Paragraph1>
				</GridItem>
			</Grid2Auto>
		</SectionPageMargin>

	</Layout>
)

export default articlesPage

const HeadingLink = styled(Link)`
	text-decoration: none;
`;


export const query = graphql`
	query  {

		consignment: file(relativePath: { eq: "articles/thumbnail_articles-circle-consignment.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		ondaLogo: file(relativePath: { eq: "articles/articles-circle-ONDA.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		caPartner: file(relativePath: { eq: "articles/ca-anz-logoarticles-circle.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		buyingGuides: file(relativePath: { eq: "articles/red-911.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		goodDesignAwards: file(relativePath: { eq: "articles/articles-circle-good-design.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		ecommerceLaunch: file(relativePath: { eq: "articles/articles-eCom-launch.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		lockdownSales: file(relativePath: { eq: "articles/article-lockdown-corner.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		lockdown: file(relativePath: { eq: "articles/lockdown-circle.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		summon100: file(relativePath: { eq: "articles/100-cars-square.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		porsche911: file(relativePath: { eq: "articles/porsche-911-square.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		fastestSelling: file(relativePath: { eq: "articles/fastest-selling-square.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		photoGuide: file(relativePath: { eq: "articles/photo-guide-square.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}


		rollercoaster: file(relativePath: { eq: "articles/rollercoaster.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		dreamGarage: file(relativePath: { eq: "articles/zoom-backgrounds.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		guideChecklist: file(relativePath: { eq: "articles/2020-guide-checklist.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}
	
		calculator: file(relativePath: { eq: "articles/calculator.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		howMuch: file(relativePath: { eq: "articles/how-much.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		tradeIn: file(relativePath: { eq: "articles/best-trade-in.jpeg" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		freePPSR: file(relativePath: { eq: "articles/freeppsr.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

		awardWinning: file(relativePath: { eq: "articles/articles-gold-car.png" }) {
			childImageSharp {
				fluid(maxWidth: 286) {
					...GatsbyImageSharpFluid
				}
			}
		}

	}
`



/*
const articlesPage = () => (
	<StaticQuery
		query={graphql`
			query  {
			allContentfulPage(filter: {contentful_id: {eq: "6oT7YoQVEnGqj8cLSwRCNl"}}) {
				edges {
				node {
					title
					content {
					content
					}
					contentReference {
					... on ContentfulItem {
						id
						title
						text {
						text
						}
						image {
							fixed(width: 200, height: 200) {
							...GatsbyContentfulFixed_noBase64
						}
						}
						urLstub
					}
					}
				}
				}
			}
			}
		`}

		render={data => {

			const title = data.allContentfulPage.edges[0].node.title;
			const text = data.allContentfulPage.edges[0].node.content.content;
			const articleList = data.allContentfulPage.edges[0].node.contentReference.map((item, i) => {

				let align = (i % 2 === 0) ? 'left' : 'right';

				let text =
						<GridItem order="1" align={align}>
							<Heading2>
								<HeadingLink to={item.urLstub}>{item.title}</HeadingLink>
							</Heading2>
							<Paragraph1>
								{item.text.text}
							</Paragraph1>
						</GridItem>


				let image =
						<GridItem order="2" >
							<ImageRoundButton size="200px" url={item.urLstub}>
							<BackgroundImage
								fixed={item.image.fixed}
								style={{
								gridColumnStart: '1',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '220px',
								height: '220px',
								clipPath: 'circle(100px at center)',
								cursor: 'pointer'
								}}
							/>
							</ImageRoundButton>
						</GridItem>


				if(i % 2 === 0) {
					return (
						<SectionPageMargin  key={item.id}>
							<Grid2Auto >
								{text} {image}
							</Grid2Auto>
						</SectionPageMargin>
					)
				} else {
					return (
						<SectionPageMargin key={item.id}>
							<Grid2Auto >
								{image} {text}
							</Grid2Auto>
						</SectionPageMargin>
					)
				}

			});

			return (

				<Layout>

					<SEO title="Summon Articles"
						description="The articles, guides and resources below we hope can help to make selling your
						prestige used car, or any car for that matter, a joyful experience."
					/>

					<PageHeader title={title} text={text}/>

					{articleList}

				</Layout>

			)
		}}
	/>
)

export default articlesPage
*/






